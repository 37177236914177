.max-width-wrapper-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.max-width-wrapper {
  max-width: 800px;
  width: 100%;
  padding: 0px 16px;
}

@media (min-width: 768px) {
  .max-width-wrapper {
    max-width: 1035px;
    width: 100%;
    padding: 0px 40px;
  }
}

@media screen and (min-width: 955px) {
  .max-width-wrapper {
    width: 100%;
    max-width: 1780px;
    padding: 0px 160px;
  }
}
@media screen and (min-width: 1460px) {
  .max-width-wrapper {
    width: 100%;
    max-width: 1728px;
    padding: 0px 200px;
  }
}
