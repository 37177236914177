.fullscreen-swiper .swiper-wrapper {
  display: flex;
  align-items: center;
}
.laptop-details-top-div {
  display: flex;
  flex: 1;
  gap: 48px;
}
.laptop-detail-left {
  display: flex;
  flex-direction: column;
  flex: 0.45;
  /* background-color: aqua; */
  height: 400px;
  gap: 60px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper {
  width: 100%;

  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  width: 100% !important;
}

.mySwiper2 {
  height: 85%;
  width: 100%;
}
.mySwiper2 .swiper-button-next {
  color: var(--blackmiple);
}
.mySwiper2 .swiper-button-prev {
  color: var(--blackmiple);
}

.mySwiper {
  height: 15%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25% !important;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.laptop-detail-right {
  display: flex;

  flex-direction: column;
  flex: 0.55;
  gap: 24px;
}
.laptop-detail-right-top-text {
  color: var(--black);

  font-size: 40px;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 40px;
}
.laptop-detail-right-second-text {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--black);

  font-size: 32px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 48px;
  letter-spacing: 0.96px;
}
.laptop-detail-right-second-text-span {
  color: var(--disabledgrey4);

  font-size: 24px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 32px;
  letter-spacing: 0.72px;
  text-decoration: line-through;
}
.color-selection-div {
  display: flex;
  margin-top: -8px;
  gap: 24px;
  align-items: center;
}
.color-selection-inner-div {
  display: flex;
  gap: 8px;
}
.color-selection-inner-div div {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}
.laptop-ram-div {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.laptop-details-specs-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
}
.laptop-specs-div {
  display: flex;
  padding: 16px;
  /* min-width: 140px; */
  align-items: center;
  gap: 8px;
  background-color: var(--cream5);
  border-radius: 7px;
}
.laptop-specs-text-div {
  display: flex;
  flex-direction: column;
}
.laptop-specs-text-top {
  color: var(--label);

  font-size: 14px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 16px;
}
.laptop-specs-text-bottom {
  color: var(--disabledgrey2);

  font-size: 14px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 16px;
}
.laptop-details-bottom-text {
  color: var(--textcolor);

  font-size: 14px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.42px;
}
.laptop-details-add-to-cart-div {
  display: flex;
  gap: 16px;
  width: 100%;
  margin: 8px 0px;
  flex: 1;
}

.laptop-details-add-to-cart-div button {
  flex: 1;
}
.laptop-delivery-div {
  display: flex;
  padding: 16px;
  /* min-width: 140px; */
  align-items: center;
  gap: 15px;

  border-radius: 7px;
}
.laptop-delivery-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cream5);
  border-radius: 7px;
  padding: 13px;
}
.laptop-delivery-text-div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.laptop-deliver-text-color {
  color: var(--black);
}

.css-39ys:focus-visible {
  outline: none !important;
}
