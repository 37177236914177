@media screen and (max-width: 768px) {
  .subnavbar-div {
    display: none;
  }
  .footer-top-container-parent {
    padding: 48px 16px;
  }
  .footer-top-container {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}
