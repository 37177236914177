.fetaured-laptop-component-container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.fetaured-laptop-component-container-img-div {
  display: flex;
  max-height: 300px;
}
.fetaured-laptop-component-container-img-div img {
  width: 100%;
  max-width: 300px;
  object-fit: cover;
}
.fetaured-laptop-component-container-content-div {
  padding: 0px 32px 56px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-height: 208px;
}
.fetaured-laptop-component-container-content-div-title {
  color: var(--black);

  font-size: 33px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 48px;
}
.fetaured-laptop-component-container-content-div-description {
  color: var(--lightgrey);

  font-size: 14px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px; /* 171.429% */
}
