.page-not-found-top-div {
  display: flex;
  flex-direction: column;
  height: max(100vh, 540px);
  justify-content: center;
  align-items: center;
}
.page-not-found-inner-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.page-not-found-image {
  width: 100%;
  margin-bottom: 60px;
}
.notfount-heading {
  color: var(--blackmiple);
  text-align: center;

  font-size: 48px;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.notfount-subheading {
  color: var(--bownbody);
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 30px;
}
