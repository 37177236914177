.nav-bar-logo {
  max-width: 92px;
  width: 100%;
  cursor: pointer;
}
.nav-bar-padding {
  padding: 0px;
}
.search-navbar {
  max-width: 400px !important;
  width: 100% !important;
  background-color: var(--bg-search);
}
.search-navbar:hover {
  background-color: var(--bg-search);
}
.search-bar-nav-bar {
  width: 100%;
}
.search-bar-nav-bar input {
  padding: 16px 16px 16px calc(1em + 32px) !important;
  width: 100% !important;
  color: var(--disabledgrey) !important;
}

.search-bar-nav-bar input::placeholder {
  color: var(--disabledgrey) !important;
}
.nav-bar-button {
  padding: 6px 25px;
  color: var(--blackmiple);
  opacity: 0.3;
  font-size: 16px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: normal;
}
.nav-bar-button-active {
  opacity: 1;
}
