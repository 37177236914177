.about-us-flex-column {
  display: flex;
  flex-direction: column;
}

.about-us-parent-div {
  display: flex;
  flex-direction: column;
  gap: 45px;
}
.about-us-top-div {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/aboutus.png');
}

.about-us-top-div p {
  color: var(--cream4);

  font-size: 26px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px; /* 92.308% */
  letter-spacing: 0.78px;
}

.about-us-upper-bottom-div {
  display: flex;
  gap: 80px;
  padding: 60px 0px;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
.about-us-upper-bottom-div-extra {
  flex-direction: column;
}
.about-us-upper-div-left {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
}
.about-us-common-heading {
  color: var(--blackmiple);

  font-size: 38px;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 150%;
}
.about-us-upper-div-left .subheading {
  color: var(--blackmiple);

  font-size: 18px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 150%;
}
.about-us-upper-div-right {
  flex: 1;
  display: flex;
}

.about-us-upper-div-right img {
  max-height: 550px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.about-us-bottom {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;
}
.about-us-common-subheading {
  font-size: 24px;
  text-align: center;
  font-weight: var(--medium);
}

.about-us-review-loop {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(331px, 1fr));
  width: 100%;
}

@media screen and (max-width: 768px) {
  .about-us-upper-bottom-div {
    flex-direction: column-reverse;
  }
  .about-us-upper-bottom-div-extra {
    flex-direction: column;
    padding: 0px 0px 115px;
  }
  .about-us-common-heading {
    text-align: center;
  }
  .about-us-upper-div-left .subheading {
    text-align: center;
  }
  .about-us-parent-div {
    gap: 0px;
  }
}
