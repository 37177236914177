.catergory-component-container {
  background-color: var(--bg-color);
  width: 160px;
  height: 128px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.catergory-component-img {
  width: 48px;
  height: 48px;
}
.catergory-component-text {
  color: var(--back);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px; /* 150% */
}
