a {
  text-decoration: none;
}
.pcontained {
  color: var(--white);
}
.poutlined1 {
  color: var(--blackmiple);
}
.poutlined2 {
  color: var(--cream3);
}
.poutlined3 {
  color: var(--disabledgrey2);
}
.poutlined5 {
  color: var(--disabledgrey3);
}
.poutlined4 {
  color: var(--white);
}
.pgreen {
  color: var(--white);
}
.phave-border {
  font-weight: var(--medium);
}
.pno-border {
  font-weight: var(--bold);
}

.plarge {
  font-size: 16px;
}
.psmall {
  font-size: 14px;
}
