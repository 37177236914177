.contact-us-parent-div {
  display: flex;
  flex-direction: column;
  gap: 45px;
}
.contact-us-top-div {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/contact-us-bg.png');
}
.contact-us-top-div p {
  color: var(--cream4);

  font-size: 26px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px; /* 92.308% */
  letter-spacing: 0.78px;
}
.contact-us-content {
  display: flex;
  flex-direction: column;
  gap: 45px;
}
.contact-us-content-top-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.contact-us-content-top-div-heading {
  color: var(--blackmiple);

  font-size: 42px;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 30px; /* 71.429% */
}
.contact-us-content-top-div-subheading {
  color: var(--bownbody);

  font-size: 20px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 30px; /* 71.429% */
}

.contact-us-content-bottom-div {
  display: flex;
  flex: 1;
  gap: 11px;
  margin-bottom: 45px;
}
.contact-us-content-bottom-div-left {
  display: flex;
  gap: 32px;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}

.contact-us-content-bottom-div-left-left {
  display: flex;
  flex-direction: column;
  gap: 38px;
  align-items: flex-start;
  flex: 1;
  min-height: 381px;
}
.contact-us-content-bottom-div-left-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.common-image-style {
  max-width: 48px;
  width: 100%;
}
.contact-us-content-heading-common {
  color: var(--blackmiple);

  font-size: 20px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 30px; /* 150% */
}
.contact-us-content-subheading-common {
  color: var(--bownbody);
}
.contact-us-content-subheading {
  color: var(--bownbody);

  font-size: 18px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 170.5%;
}
.contact-us-icon-div {
  display: flex;
  gap: 32px;
}
.contact-us-icon-div img {
  cursor: pointer;
}
.contact-us-content-bottom-div-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px;
  background-color: var(--bg-color5);
}

.contact-us-form-div {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}
.contact-us-common-field {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}
.contact-us-common-field label {
  color: var(--blackmiple);
  /* font-family: 'SF Pro Display'; */
  font-size: 20px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 30px;
}
.contact-us-common-fiel-design {
  background-color: var(--white);
  border: 1px solid var(--disabledgrey);
  border-radius: 8px;
}

.contact-us-common-fiel-design input,
textarea {
  font-family: 'sf pro display';
  color: var(--blackmiple);
  font-size: 20px;
}

.contact-us-common-fiel-design textarea {
  font-family: 'sf pro display';
  color: var(--blackmiple);
  font-size: 20px;
}
.contact-us-common-fiel-design::placeholder {
  color: var(--disabledgrey);
}

.contact-us-button-div {
  display: flex;
  width: 100%;
}
.contact-us-button-div button {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .contact-us-content-bottom-div {
    gap: 33px;

    flex-direction: column-reverse;
    margin-bottom: 100px;
  }
  .contact-us-content-bottom-div-left-left {
    display: flex;
    flex-direction: row;
    gap: 38px;
    align-items: flex-start;
    flex: 1 1;
    min-height: unset;
  }
  .contact-us-content-bottom-div-left-inner {
    gap: 15px;
    align-items: center;
  }
}
