.sub-nav-bar-top-container {
  height: 48px;
  background-color: var(--cream3);
  display: flex;
  justify-content: center;
  align-self: center;
}
.sub-nav-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 130px;
}
.sub-nav-bar-container-left-div {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  position: relative; /* Added for positioning the dropdown */
}
.sub-nav-bar-container-left-div p {
  color: var(--bownbody);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 32px;
}

.sub-nav-bar-container-left-div .dropdown {
  z-index: 3;
  display: none; /* Hide dropdown by default */
  position: absolute;
  top: 100%; /* Position dropdown below the parent */
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 10px; /* Added padding for better spacing */
  margin: 0;
  width: max-content;
  background-color: var(--cream3);
  border-radius: 0px;
}

.sub-nav-bar-container-left-div:hover .dropdown {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Create two equal-width columns */
  gap: 10px; /* Space between items */
}

.sub-nav-bar-container-left-div .dropdown li {
  padding: 8px 16px;
  cursor: pointer;
  /* Ensure background color for better readability */
  border-radius: 0px; /* Optional: Add border-radius for better visuals */
  text-align: center; /* Center-align text in each item */
}

.sub-nav-bar-container-left-div .dropdown li:hover {
  background-color: #f1f1f1; /* Optional: Add hover effect for dropdown items */
}
.sub-nav-bar-container-left-div .dropdown li {
  color: var(--bownbody);

  font-size: 16px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 32px;
}

.sub-nav-bar-container-center-div {
  justify-content: center;
  padding: auto;
}
.sub-nav-bar-container-right-div {
  justify-content: flex-end;
}
