.banner-parent-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(
    96deg,
    var(--brownmiple) 3.22%,
    var(--blackmiple) 122.05%
  );
  width: 100%;
}
.banner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.banner-center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner-center-container-heading {
  color: var(--white);
  text-align: center;

  font-size: 72px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 72px; /* 100% */
  letter-spacing: -0.72px;
}
.banner-center-container-heading span {
  font-weight: var(--medium);
}

.banner-center-container-subheading {
  color: var(--brown);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 32px; /* 200% */
  margin-bottom: 40px;
}
.banner-container-common {
  display: flex;
  flex: 1;
}
.banner-container-common-top img {
  width: 100%;
}
.banner-container-common-bottom {
  justify-content: flex-end;
}
.banner-container-common-bottom img {
  width: 100%;
  max-width: 349px;
}

@media screen and (max-width: 768px) {
  .banner-parent-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: linear-gradient(
      100deg,
      var(--black4) 42.36%,
      var(--black) 98.65%
    );

    width: 100%;
  }

  .banner-container {
    padding: 104px 16px;
    position: relative;
  }
  .banner-container-common-top {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
  }
  .banner-container-common-top img {
    width: 100%;
    max-width: 50px;
  }
  .banner-container-common-top-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 200px;
  }
  .banner-container-common-top-bottom img {
    width: 100%;
    max-width: 107px;
  }
  .banner-center-container {
    width: 100%;
    display: flex;
    justify-content: center;

    z-index: 5;
  }
  .banner-container-common-bottom {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 168px;
  }
}
