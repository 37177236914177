.products-parent-div {
  padding: 56px 0px;
}
.products-top-container-left {
  display: flex;
  gap: 32px;
}
.products-top-container-left .active {
  color: var(--black);
  text-decoration: underline;
}

.products-top-container-left,
.categories-top-container-right p {
  color: var(--darkgrey);
  text-align: center;

  font-size: 18px;

  font-weight: var(--medium);
  line-height: 32px;
  cursor: pointer;
}
.products-bottom-parent-container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}
@media screen and (max-width: 768px) {
  .products-top-container-left,
  .categories-top-container-right p {
    font-size: 16px;
  }
  .categories-top-container-right {
    display: none;
  }
}
