.categories-parent-container {
  padding: 50px 0px;
  background-color: var(--bg-color2);
}
.categories-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.categories-top-container {
  display: flex;
  flex: 1;
  align-items: center;
}
.categories-top-container-left {
  flex: 1;
}
.categories-top-container-left p {
  color: var(--black);
  font-size: 24px;

  font-weight: var(--medium);
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.24px;
}
.categories-top-container-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 16px;
}
.categories-bottom-parent-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .categories-parent-container {
    padding: 64px 0px;
  }
  .categories-container {
    gap: 48px;
  }
  .categories-bottom-parent-container {
    gap: 16px;
  }
}
