.product-component-parent-container {
  /* max-width: 268px; */
  width: 100%;
  background-color: var(--bg-color3);
  position: relative;
  cursor: pointer;
}
.product-component-container {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.product-component-heart {
  display: flex;
  justify-content: flex-end;
}
.product-component-heart svg {
  cursor: pointer;
}
.product-component-img-div {
  display: flex;
  justify-content: center;
  min-height: 160px;
}
.product-component-img-div img {
  max-width: 160px;
  width: 100%;
  object-fit: contain;
}
.product-component-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  min-height: 88px;
}
.product-component-content-title {
  color: var(--blackmiple);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px;
  min-height: 96px;
}
.product-component-content-price {
  color: var(--blackmiple);
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px;
}
.product-component-content-discount {
  color: var(--disabledgrey);
  font-weight: var(--medium);
  text-decoration: line-through;
}
.product-component-button {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

@media screen and (max-width: 768px) {
  .product-component-container {
    padding: 24px 12px;
    gap: 8px;
  }
  .products-bottom-parent-container {
    grid-template-columns: repeat(auto-fill, minmax(166px, 1fr));
  }
  .product-component-img-div img {
    max-width: 104px;
  }
  .product-component-content-price {
    font-size: 18px;
  }
  .product-component-content {
    min-height: 108px;
  }
}
