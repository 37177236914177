.laptop-textual-details-top-div {
  display: flex;
  background-color: var(--bg-color2);
}
.laptop-textual-details-inner-div {
  padding: 80px 0px;
  width: 100%;
}
.laptop-textual-details-div {
  display: flex;
  flex-direction: column;
  padding: 48px 40px;
  gap: 32px;
  background-color: var(--white);
}
.buttons-extra {
  display: flex;
  gap: 8px;
  align-items: center;
}
.laptop-textual-details-div-top-heading {
  color: var(--black);

  font-size: 24px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 32px;
}
.laptop-textual-details-div-top-details {
  color: var(--textcolor2);

  font-size: 14px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px;
}
.laptop-textual-details-button-div {
  display: flex;
  justify-content: center;
}
.laptop-textual-details-screen-text {
  color: var(--black);

  font-size: 20px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px;
  margin-bottom: 16px;
}
.laptop-textual-details-screen-under-div {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.laptop-textual-details-screen-under-inner-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--borderbottom);
  padding-bottom: 8px;
}
.laptop-textual-details-screen-under-inner-div-text1 {
  color: var(--black);

  font-size: 16px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 24px;
}

.laptop-textual-details-screen-under-inner-div-text2 {
  color: var(--black);

  font-size: 15px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 24px;
}
