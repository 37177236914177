.laptop-screen-div {
  display: flex;
  flex-direction: column;
}
.laptop-top-div {
  display: flex;
  padding: 40px 0px;
}

.laptop-bottom-div {
  display: flex;
  gap: 32px;
  padding: 24px 0px 56px 0px;
}
.laptop-left-div {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 0.25;
}

.laptop-right-div {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 0.75;
}

.laptop-accordion-div {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.laptop-accordion-heading-div {
  border-bottom: 1px solid var(--border);
  min-height: unset !important;
  padding: 0px;

  color: var(--blackmiple);

  font-size: 18px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.54px;
}

.laptop-accordion-div::before {
  display: none !important;
}
.laptop-accordion-details-div {
  padding: 0px;
}
.laptop-accordion-heading-div div {
  margin: 0px !important;
  padding: 12px 0px;
}

.laptop-accordion-details-inner-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.laptop-input-fields-div {
  display: flex;
  gap: 20px;
}
.laptop-input-fields-left-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.laptop-input-fields-right-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}
.laptop-common-label {
  color: var(--label);

  font-size: 14px;

  font-weight: var(--regular);
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.07px;
}
.laptop-filter-button-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.laptop-filter-button-div p:first {
  color: var(--blackmiple);

  font-size: 14px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.07px;
}

.laptop-search-bar-div {
  display: flex;
  width: 100%;
}
.laptop-search-bar {
  margin: 0px;
  width: 100%;
  border-radius: 8px;
}

.category-overflow-control-div {
  max-height: 200px;
  height: 100%;
  overflow: auto;
}
.category-label-text {
  color: var(--blackmiple);

  font-size: 15px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 24px;
}

.category-label-subtext {
  color: var(--label2);

  font-size: 12px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 24px;
}

.laptop-right-div-top-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.laptop-select-position {
  max-width: 256px;
  width: 100%;
}

.laptop-select-position-input {
  width: 100%;
}
.laptop-select-position-input
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 14px 16px;
}
.laptop-select-position-input .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.laptop-total-products {
  color: var(--textcolor);

  font-size: 16px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 16px; /* 100% */
  letter-spacing: 0.48px;
}

.laptop-total-products span {
  color: var(--black);

  font-size: 20px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 16px; /* 100% */
  letter-spacing: 0.48px;
}
.laptop-pagination-div {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

@media screen and (max-width: 977px) {
  .laptop-left-div {
    display: none;
  }
  .laptop-right-div {
    flex: 1;
  }
}
