.customer-review-div {
  display: flex;
  flex-direction: column;
  padding: 32px;
  max-width: 288px;
  border-radius: 4px;
  border: 1px solid var(--disabledgrey);
  gap: 32px;
}
.customer-review-star {
  display: flex;
  gap: 4px;
}
.review-text {
  color: var(--blackmiple);

  font-size: 18px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 120%;
}

.review-bottom-div {
  display: flex;
  gap: 16px;
  align-items: center;
}
.review-bottom-div img {
  max-width: 56px;
  width: 100%;
}
.review-bottom-div-text {
  font-weight: var(--bold);
}
.review-bottom-div-subtext {
  font-weight: var(--medium);
}
