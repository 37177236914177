.sub-hero-section-parent-container {
  display: flex;
  flex: 1;
  margin-top: 16px;
}
.sub-hero-section-left-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sub-hero-section-left-container-top-container {
  display: flex;
  flex: 1;
}
.sub-hero-section-left-container-top-container-img-div {
  display: flex;
  flex: 0.4;
}
.sub-hero-section-left-container-top-container-img {
  width: 100%;
  object-fit: contain;
  min-width: 100px;
}
.sub-hero-section-left-container-top-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.6;
  gap: 16px;
  padding-right: 48px;
}
.sub-hero-section-left-container-top-container-content-heading {
  color: var(--black);
  font-weight: var(--medium);
  line-height: 40px;
  font-size: 49px;
}
.sub-hero-section-left-container-bottom-container-left-container-heading {
  font-size: 29px;
}

.sub-hero-section-left-container-bottom-container {
  display: flex;
  flex: 1;
}

.sub-hero-section-left-container-bottom-container-left-container {
  display: flex;
  flex: 1;
  background-color: var(--bg-color);
}
.sub-hero-section-left-container-bottom-container-left-container-img {
  width: 100%;
  max-width: 88px;
  object-fit: contain;
}
.sub-hero-section-left-container-bottom-container-right-container {
  display: flex;
  flex: 1;
  background-color: var(--blackmiple);
}
.sub-hero-section-left-container-bottom-container-right-container-img {
  max-width: 107px;
  object-fit: contain;
  width: 100%;
}
.sub-hero-section-left-container-bottom-container-right-container-heading {
  color: var(--white);
}

.sub-hero-section-left-container-bottom-container-right-container-subheading {
  color: var(--lightgrey);
}
.sub-hero-section-right-container {
  display: flex;
  flex: 1;
  background: var(--bg-color);

  align-items: center;
  position: relative;
}
.sub-hero-section-right-container-content {
  padding: 44px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sub-hero-section-right-container-heading {
  color: var(--black);
  font-weight: var(--regular);
  line-height: 56px;
  font-size: 64px;
}
.sub-hero-section-right-container-heading-medium {
  font-weight: var(--medium);
}
.sub-hero-section-right-container-subheading {
  color: var(--lightgrey);
  font-weight: var(--medium);
  line-height: 24px;
  font-size: 14px;
}
.sub-hero-section-laptop-image-div {
  padding: 44px 0px;
}
.sub-hero-section-laptop-image {
  width: 100%;
  object-fit: cover;
  min-width: 100px;
}

@media screen and (max-width: 768px) {
  .mobile-view {
    margin-top: 0px;
  }
  .sub-hero-section-parent-container {
    margin-top: 0px;
  }
  .sub-hero-section-parent-container {
    flex-direction: column;
  }
  .sub-hero-section-left-container {
    flex-direction: column-reverse;
  }
  .sub-hero-section-left-container-bottom-container {
    flex-direction: column;
  }

  .sub-hero-section-left-container-bottom-container-left-container {
    flex-direction: column;
    padding: 40px 16px;
    gap: 24px;
    align-items: center;
  }

  .sub-hero-section-left-container-bottom-container-left-container-img {
    max-width: 192px;
  }

  .sub-hero-section-left-container-top-container-content {
    gap: 8px;
    padding-right: 0px;
    width: 100%;
    align-items: center;
  }

  .sub-hero-section-left-container-bottom-container-left-container-heading {
    font-size: 34px;
  }
  .sub-hero-section-right-container-subheading {
    font-size: 16px;
    text-align: center;
  }

  .sub-hero-section-left-container-bottom-container-right-container {
    background-color: var(--black3);
    flex-direction: column;
    padding: 40px 16px;
    align-items: center;
    gap: 24px;
  }
  .sub-hero-section-left-container-bottom-container-right-container-img {
    max-width: 325px;
  }

  .sub-hero-section-left-container-top-container {
    flex-direction: column;
    padding: 40px 16px;
    gap: 24px;
    align-items: center;
  }

  .sub-hero-section-left-container-top-container-img-div {
    width: 100%;
    justify-content: center;
  }

  .sub-hero-section-left-container-top-container-img {
    max-width: 240px;
    width: 100%;
  }

  .sub-hero-section-left-container-top-container-content-heading {
    font-weight: var(--regular);

    font-size: 34px;
  }
  .sub-hero-section-left-container-top-container-content-heading span {
    font-weight: var(--medium);
  }

  .sub-hero-section-right-container {
    flex-direction: column-reverse;
    padding: 40px 16px;
    align-items: center;
    gap: 24px;
  }
  .sub-hero-section-laptop-image-div {
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .sub-hero-section-laptop-image {
    max-width: 340px;
  }
  .sub-hero-section-right-container-content {
    padding: 0px;
    align-items: center;
  }
  .sub-hero-section-right-container-heading {
    font-size: 34px;
  }
  .full-width-button-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .full-width-button {
    width: 100%;
  }
}
