.hero-sectio-top-container {
  background: linear-gradient(96deg, #3b2824 2.22%, #60413b 98.04%);
  height: max(calc(77vh - 136px), 400px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-section-content {
  display: flex;
  gap: 19px;
  align-items: center;
  flex: 1;
}
.hero-section-left-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.hero-section-left-div-top-text {
  color: var(--cream3);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 32px;
  opacity: 0.4;
}

.hero-section-left-div-center-text {
  color: var(--cream3);
  font-size: 68.939px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 70px; /* 75% */
  letter-spacing: 2.368px;
}
.hero-section-left-div-center-text span {
  font-weight: var(--bold);
}

.hero-section-left-div-bottom-text {
  color: var(--cream3);
  font-size: 18px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.54px;
}
.desktop-view {
  display: flex;
}
.mobile-view {
  display: none;
}
.hero-section-right-div {
  display: flex;
  flex: 1;
  align-items: end;
}

.hero-section-image {
  width: 100%;
  object-fit: cover;
  min-width: 200px;
}

@media screen and (max-width: 768px) {
  .hero-sectio-top-container {
    background: linear-gradient(91deg, var(--black2) 0.64%, var(--black2) 101%);
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-section-content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
    flex: 1 1;
    padding: 88px 0px;
  }
  .hero-section-left-div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  .hero-section-left-div-top-text {
    color: var(--disabledgrey);
    font-size: 25px;
    font-style: italic;
    font-weight: var(--bold);
    line-height: 32px;
    opacity: 0.4;
  }
  .hero-section-left-div-center-text {
    color: var(--white);

    text-align: center;

    font-size: 72px;
    font-style: normal;
    font-weight: var(--regular);
    line-height: 72px; /* 100% */
    letter-spacing: -0.72px;
  }
  .hero-section-left-div-center-text span {
    font-weight: var(--bold);
  }

  .hero-section-left-div-bottom-text {
    color: var(--disabledgrey);
    font-size: 19px;
    font-style: normal;
    font-weight: var(--medium);
    line-height: 24px;
    letter-spacing: 0.54px;
  }
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: flex;
    margin-top: 16px;
  }
}
