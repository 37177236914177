.footer-top-container-parent {
  padding: 104px 0px;
  background-color: var(--cream3);
  display: flex;
}
.footer-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.footer-top-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.footer-top-container-common {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.footer-top-container-left {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 25px;
}
.footer-top-container-common-font {
  color: var(--brownmiple);

  font-size: 18px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 170.5%;
  cursor: pointer;
}
.without-pointer {
  cursor: auto;
}
.footer-top-container-common-heading {
  color: var(--blackmiple);

  font-size: 16px;
  font-style: normal;
  font-weight: var(--medium);
  line-height: 16px; /* 100% */
  margin-bottom: 8px;
}
.footer-top-container-common-heading-extra {
  font-weight: var(--bold);
}
.footer-payment {
  display: flex;
  gap: 20px;
  margin-top: 28px;
}
.footer-bottom-container {
  display: flex;
  gap: 36px;
}
.footer-bottom-container svg {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .footer-top-container-parent {
    padding: 48px 16px;
  }
  .footer-top-container {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .footer-top-container-left {
    gap: 16px;
    padding-right: 0px;
    align-items: center;
  }
  .nav-bar-logo {
    max-width: 92px;
    width: 100%;
  }
  .footer-top-container-common {
    align-items: center;
    flex: 1 1;
    width: 100%;
    gap: 4px;
  }
  .footer-top-container-common-font {
    font-size: 14px;

    text-align: center;
  }
  .footer-bottom-container {
    justify-content: center;
  }
}
