.accordion-parent-div {
  padding: 60px 0px;
}
.accordion-div {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
}

.accordion-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.accordion-top-container-heading {
  color: var(--bownbody);
  text-align: center;

  font-size: 28px;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 120%; /* 33.6px */
}

.accordion-top-container-subheading {
  color: var(--bownbody);
  text-align: center;

  font-size: 22px;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 120%; /* 33.6px */
}

.accordion-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.accordion-bottom-container-extra {
  font-weight: var(--regular);
  margin-bottom: 8px;
}

.accordion-center-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.accordion-center-container-main {
  border-radius: 0px !important;
  border: 1px solid var(--disabledgrey2);
  box-shadow: none;
}
.accordion-center-container-summary {
  padding: 20px 24px;
  color: var(--bownbody);
  font-family: 'SF Pro Display';
  font-size: 18px;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 120%;
}
.accordion-center-container-summary div {
  margin: 0px;
}
@media screen and (max-width: 768px) {
  .accordion-parent-div {
    display: none;
  }
}
