@font-face {
  font-family: 'sf pro display';
  src: local('sf pro display'),
    url(./fonts/SFPRODISPLAYREGULAR.OTF) format('opentype');
}

@font-face {
  font-family: 'sf pro display bold';
  font-weight: 700;
  src: local('sf pro display'),
    url(./fonts/SFPRODISPLAYBOLD.OTF) format('opentype');
}

@font-face {
  font-family: 'sf pro display';
  font-weight: 500;
  src: local('sf pro display'),
    url(./fonts/SFPRODISPLAYMEDIUM.OTF) format('opentype');
}

:root {
  --cream1: #f3d7b2;
  --cream2: #edd3ae;
  --cream3: #eacba7;
  --cream4: #eee4e2;
  --cream5: #f4f4f4;
  --black: #000000;
  --black2: #211c24;
  --black3: #353535;
  --black4: #2e2e2e;
  --blackmiple: #2e1f1c;
  --lightgrey: #909090;
  --darkgrey: #8b8b8b;
  --disabledgrey: #9f9998;
  --disabledgrey2: #7a716f;
  --disabledgrey3: #d5d5d5;
  --disabledgrey4: #a0a0a0;
  --bg-color: #ededed;
  --bg-color2: #fafafa;
  --bg-color3: #f7f7f7;
  --bg-color4: #f9f9f9;
  --bg-color5: #f7f8f9;
  --bg-search: #f5f5f5;
  --white: #ffffff;
  --brown: #787878;
  --bownbody: #544845;
  --brownmiple: #65443e;
  --border: #b5b5b5;
  --slider: #414141;
  --label: #a7a7a7;
  --label2: #929292;
  --textcolor: #6c6c6c;
  --textcolor2: #9d9d9d;
  --borderbottom: #cdcdcd;

  --regular: 400;
  --medium: 500;
  --bold: 700;
}
.common-font {
  font-family: 'sf pro display';
  margin: 0px;
}
