.common-button {
  width: fit-content;
  cursor: pointer;
}

.contained {
  background-color: var(--blackmiple);
  color: var(--white);
  border: none;
}
.outlined1 {
  background-color: transparent;
  border: 1px solid var(--blackmiple);
  color: var(--blackmiple);
}
.outlined2 {
  background-color: transparent;
  border: 1px solid var(--cream3);
  color: var(--cream3);
}
.outlined3 {
  background-color: transparent;
  border: 1px solid var(--disabledgrey3);
  color: var(--disabledgrey2);
}
.outlined4 {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);
}
.green {
  background-color: #25d366;
  color: var(--white);
  border: none;
}
.have-border {
  border-radius: 8px;
}
.no-border {
  border-radius: 0px;
}
.large {
  padding: 16px 56px;
}
.small {
  padding: 16px 24px;
}
.xsmall {
  padding: 10px 26px;
}
.disabledCss {
  background-color: var(--disabledgrey);
  cursor: auto;
}
